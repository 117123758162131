import React, { Fragment } from 'react';
import BaseComponent from '../../components/component/base';
import Datatable from '../../components/datatable';
import { restoreFilters, storeFilters } from '../Scheduler/filter-projects';
import ModalProject from '../Scheduler/modal-project';

export function defaultCols(t) {
    return [
        {
            Header: t(`page.operations.widget.tableOfProjects.label`),
            accessor: "label_1",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.clientName`),
            accessor: "addressName_17",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.contractSigned`),
            accessor: "sclr_22",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.diffNowContractSigned`),
            accessor: "sclr_23",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.type`),
            accessor: "type_2",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.status`),
            accessor: "status_3",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.pvType`),
            accessor: "pvType_4",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.pvAmount`),
            accessor: "pvAmount_5",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.sinceContracted`),
            accessor: "sclr_6",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.since`),
            accessor: "sclr_7",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.nps`),
            accessor: "nps_8",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.cityName`),
            accessor: "cityName_18",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.pm`),
            accessor: "fullNameInv_9",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.sm`),
            accessor: "fullNameInv_10",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.bipvDesigner`),
            accessor: "fullNameInv_12",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.costController`),
            accessor: "fullNameInv_24",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.electricalDesigner`),
            accessor: "fullNameInv_13",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.constructionEngineer`),
            accessor: "fullNameInv_14",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.pmoManager`),
            accessor: "fullNameInv_27",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.logisticsManager`),
            accessor: "fullNameInv_26",
        },
        {
            Header: t(`page.operations.widget.tableOfProjects.designManager`),
            accessor: "fullNameInv_28",
        },
    ]
}

class WidgetTableOfProjects extends BaseComponent {

    constructor(props) {
        super(props);
        this.datacols = defaultCols(props.t);
        this.actioncols = [
            {
                Header: (<>&nbsp;</>),
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 135,
            }
        ];
        this.state = {
            modalProject: false,
            isLoading: true,
            modalData: {},
            columns: this.datacols.concat(this.actioncols),
            dashboards: [],
            dashboard: {},
            checklistDefaults: {}
        };
        this.onResizedTable = this.onResizedTable.bind(this);
    }

    componentDidMount() {
        window.addEventListener("ON_RESIZED_TABLE", this.onResizedTable, false);
        this.fetchConfig(() => {
            this.parsePathAndOpenModal();
        })
    }

    componentWillUnmount() {
        window.removeEventListener('ON_RESIZED_TABLE', this.onResizedTable, false);
    }

    fetchConfig(callback) {
        this.props.api.post(
            `/project/data`,
            {
                "file": {
                    "names": ["checklist"]
                }
            },
            (data) => {
                this.setState({ checklistDefaults: data.checklist }, callback);
            },
            (errorObject) => {
                this.afterFetchError(errorObject);
            }
        );
    }

    afterFetchError(errorObject) {
        this.setState({ isLoading: false }, () => {
            this.props.showToast({
                errorObject: errorObject,
                title: this.props.t('common.toast.error'),
                color: 'danger'
            });
        })
    }

    onResizedTable(e) {
        if (e.detail?.table !== 'dashboard-projects-table') {
            return;
        }
        let updatedFilters = restoreFilters();
        for (let i = 0; i < e.detail.data?.newResized?.length; i++) {
            const accessor = e.detail.data?.newResized[i].id;
            const width = e.detail.data?.newResized[i].value;
            if (!accessor || !width) {
                continue;
            }
            updatedFilters[`dashboard_${accessor}_width`] = width;
        }
        storeFilters(updatedFilters);
    }

    parsePathAndOpenModal() {
        const particles = this.props.location.hash.replace('#', '').split('/').filter(Boolean);
        if (particles.length < 3) {
            return;
        }
        const path = particles[0];
        const uuid = particles[1];
        const view = particles[2];
        const subuuid = particles[3] || '';
        switch (path) {
            case 'project':
                this.showModal(null, 'modalProject', { projectuuid: uuid, uri: `/operations/#/project/${uuid}/${view}/${subuuid}`, activeTab: view })
                break;

            default:
                break;
        }
    }

    showModal(e, modal, modalData) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (modalData.uri) {
            this.props.openLink(modalData.uri, '_hash');
        }
        this.setState({ [modal]: true, modalData: modalData })
    }

    hideModal(modal, reloadData) {
        this.props.openLink(`/operations/`, '_hash');
        this.setState({
            [modal]: false,
            modalData: {}
        }, () => {
            if (reloadData) {
                this.props.fetchData();
            }
        });
    }

    render() {
        const {
            modalProject,
            modalData,
        } = this.state;

        return this.props.isLoading ?
            (
                <></>
            ) : (
                <Fragment>
                    {
                        modalProject && (
                            <ModalProject
                                t={this.props.t}
                                api={this.props.api}
                                utils={this.props.utils}
                                openLink={this.props.openLink}
                                getUser={this.props.getUser}
                                location={this.props.location}
                                showToast={this.props.showToast}
                                getRegionIdent={this.props.getRegionIdent}
                                isRegion={this.props.isRegion}
                                showModal={modalProject}
                                modalTitle={this.props.t(`page.scheduler.modal.projectDetails.title`)}
                                onModalHide={(reloadData) => this.hideModal('modalProject', reloadData)}
                                onAfterSubmit={(reloadData, hideModal) => hideModal && this.hideModal('modalProject', true)}
                                data={modalData}
                                activeTab={modalData.activeTab}
                                buildLink={this.props.buildLink}
                                hasPermission={this.props.hasPermission}
                            />
                        )
                    }
                    <div id="dashboard-projects">
                        <Datatable
                            id={'dashboard-projects-table'}
                            endpoint={`project/table`}
                            showCsvLink={true}
                            columns={this.state.columns}
                            sort={[
                                {
                                    id: "label_1",
                                    desc: true
                                }
                            ]}
                            actions={[
                                {
                                    action: 'edit',
                                    style: { marginRight: 15 },
                                    callback: (data) => {
                                        this.showModal(undefined, 'modalProject', { projectuuid: data.uuid_0, uri: `/operations/#/project/${data.uuid_0}/details`, activeTab: "details" })
                                    }
                                }
                            ]}
                            withData={{
                                filter: this.props.filters,
                            }}
                            transform={[
                                {
                                    'field': 'label_1',
                                    'fn': (data) => {
                                        return (
                                            <button
                                                className='btn-fake-link'
                                                onClick={(e) => this.showModal(e, 'modalProject', { projectuuid: data.uuid_0, uri: `/operations/#/project/${data.uuid_0}/details`, activeTab: "details" })}
                                            >
                                                {data.label_1}
                                            </button>
                                        )
                                    }
                                }
                            ]}
                            reMapRows={(rows) => {
                                const allRows = rows.map(row => {
                                    let newRow = { ...row };
                                    Object.keys(newRow).forEach((key) => {
                                        if (key.endsWith('_props')) {
                                            return;
                                        }
                                        let divStyle = { padding: 5, borderRadius: 5, ...newRow[`${key}_props`] || {} };
                                        let checklistColumnName = key.replace('checklist_', '');
                                        let checklistColumnNameParts = checklistColumnName.split('_');
                                        checklistColumnName = checklistColumnNameParts.join('_').replace('__', ''); //;checklistColumnNameParts.pop();
                                        let accessor = key; // this.props.api.hashCode(key.normalize('NFC'), 'cl_');
                                        newRow[accessor] = <div style={divStyle}>{newRow[key]}</div>
                                    });
                                    return newRow;
                                });
                                return allRows;
                            }}
                            afterFetch={(res, callback) => {
                                const newColumns = [];
                                res.columns.forEach(columnName => {
                                    if (columnName.startsWith('checklist_')) {
                                        const checklistColumnNameOrg = columnName;
                                        let checklistColumnName = columnName.replace('checklist_', '');
                                        let checklistColumnNameParts = checklistColumnName.split('_');
                                        checklistColumnName = checklistColumnNameParts.join('_').replace('__', ''); //;checklistColumnNameParts.pop();
                                        if (checklistColumnName.includes('#date')) {
                                            checklistColumnName = `📅 ${checklistColumnName.replace('(#date)', '')}`;
                                        } else {
                                            checklistColumnName = `☑️ ${checklistColumnName}`;
                                        }
                                        const accessor = checklistColumnNameOrg; // this.props.api.hashCode(checklistColumnNameOrg.normalize('NFC'), 'cl_');
                                        newColumns.push({
                                            Header: checklistColumnName,
                                            accessor: accessor,
                                        })
                                    }
                                });
                                const allColumns = this.datacols.concat(newColumns).concat(this.actioncols).map(col => {
                                    const hiddenKey = `dashboard_${col.accessor}_hidden`;
                                    const widthKey = `dashboard_${col.accessor}_width`;
                                    let newCol = {
                                        ...col,
                                        hidden: this.getConf(this.props.filters, hiddenKey, false),
                                        width: this.getConf(this.props.filters, widthKey, 150),
                                    };
                                    return newCol;
                                });
                                this.setState({
                                    columns: allColumns
                                }, () => {
                                    callback(res)
                                })
                            }}
                            {...this.props}
                        />
                    </div>
                </Fragment>
            );
    }
}

export default WidgetTableOfProjects
